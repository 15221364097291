import React, { useCallback } from 'react'

import styles from './FromAuthors.module.css'

export const ControlElement = ({ index, isActive, onClick }) => {
    const handleOnClick = useCallback(() => { onClick(index) }, [index])
    const theme = `${styles.control} ${isActive ? styles.active : ''}`
    return (
        <li>
            <button onClick={handleOnClick} className={theme} />
        </li>
    )
}
