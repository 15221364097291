import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { FromAuthor } from './FromAuthor'
import { Controls } from './Controls'
import styles from './FromAuthors.module.css'
import { TEXTS } from '../../constants'

export const FromAuthors = () => {
    const { fromAuthors } = useStaticQuery(graphql`
    {
      fromAuthors: allContentfulFromAuthors {
       nodes {
         id
         fio
         position
         photo {
          localFile {
            childImageSharp {
              fluid(cropFocus: CENTER, fit: COVER, maxHeight: 200, maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
         }
         text {
           json
         }
       }
      }
    }
    `)
    const [item, setItem] = useState(0)
    const min = 0
    const max = fromAuthors.nodes.length - 1
    const onLeft = () => {
        const newValue = item - 1
        setItem(Math.min(Math.max(newValue, min), max))
    }
    const onRight = () => {
        const newValue = item + 1
        setItem(Math.min(Math.max(newValue, min), max))
    }
    return (
        <div className={styles.fromAuthors}>
            <button onClick={onLeft} className={`${styles.button} ${styles.left}`}>{TEXTS.prev}</button>
            <FromAuthor {...fromAuthors.nodes[item]} />
            <button onClick={onRight} className={`${styles.button} ${styles.right}`}>{TEXTS.next}</button>
            <Controls current={item} count={fromAuthors.nodes.length} onClick={setItem} />
        </div>
    )
}
