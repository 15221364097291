import React, { PureComponent } from 'react'

import { Progress } from './Progress'
import { TimeStamp } from './TimeStamp'
import { TEXTS } from '../../constants'
import Play from '../../images/icons/play.inline.svg'
import Pause from '../../images/icons/pause.inline.svg'
import Volume from '../../images/icons/volume.inline.svg'

import styles from './Player.module.css'

export class Player extends PureComponent {

    state = {
        volume: 0.5,
        currentTime: 0,
        duration: 0,
        isPlayed: false,
    }

    constructor (props) {
        super(props)
        this.audio = React.createRef()
    }

    componentDidMount () {
        this.audio.current.addEventListener('loadedmetadata', this.onLoad)
        this.audio.current.addEventListener('ended', this.onEnd)
    }

    clearListenTrack () {
        if (this.listenTracker) {
            clearInterval(this.listenTracker)
            this.listenTracker = null
        }
    }

    togglePlayback = () => {
        if (this.state.isPlayed) {
            this.onPause()
        } else {
            this.onPlay()
        }
    }

    onLoad = () => {
        const { duration } = this.audio.current
        this.setState({ duration })
    }

    onPlay = () => {
        this.audio.current.play()
        if (!this.listenTracker) {
            this.update()
            this.listenTracker = setInterval(this.update, 500)
        }
        this.setState({ isPlayed: true })
    }

    onPause = () => {
        this.audio.current.pause()
        this.clearListenTrack()
        this.setState({ isPlayed: false })
    }

    onEnd = () => {
        this.onPause()
    }

    onVolumeChange = (volume) => {
        this.audio.current.volume = volume
        this.setState({ volume })
    }

    onRewind = (percent) => {
        const { duration } = this.state
        const currentTime = percent * duration
        this.audio.current.currentTime = currentTime
        this.setState({ currentTime })
    }

    update = () => {
        const { currentTime } = this.audio.current
        this.setState({ currentTime })
    }

    render () {
        const { ogg, mp3, inverter } = this.props
        const { currentTime, duration, volume, isPlayed } = this.state
        //TODO is loaded
        return (
            <div className={`${styles.player} ${inverter ? styles.inverted : null}`}>
                <audio ref={this.audio} preload="metadata">
                    <source src={ogg} type="audio/ogg"/>
                    <source src={mp3} type="audio/mpeg"/>
                </audio>
                <button className={styles.button} onClick={this.togglePlayback} title={isPlayed ? TEXTS.pause : TEXTS.play}>
                    {isPlayed ? <Pause className={styles.icon} /> : <Play className={styles.icon} />}
                </button>
                <Volume className={`${styles.icon} ${styles.volume}`}/>
                <Progress theme={styles.volume} onChange={this.onVolumeChange} value={volume} />
                <TimeStamp time={currentTime}/>
                <Progress theme={styles.playback} onChange={this.onRewind} value={currentTime / duration || 0} />
                <TimeStamp time={duration}/>
            </div>
        )
    }
}
