import React from 'react'

import { Song } from './Song'

import styles from './songs.module.css'

const getOrder = (index, count) => {
    const half = Math.floor(count / 2)
    if (index <= half) {
        return index * 2 - 1
    } else {
        return (index - half) * 2
    }
}

export const Songs = ({ songs }) => {
    return (
        <div className={styles.songs}>
            {songs.map(({ id, ...props}, index) => {
                const number = index + 1
                const order = getOrder(number, songs.length)
                return <Song key={id} number={number} order={order} {...props}/>
            })}
        </div>
    )
}
