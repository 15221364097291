import React from 'react'
import { Link } from 'gatsby'

import { TEXTS } from '../../constants'
import grantsLogo from '../../images/grants-logo.svg'
import speaker from '../../images/icons/speaker.svg'

import styles from './layout.module.css'

export const Footer = () => (
    <footer className={styles.footer}>
        <img src={grantsLogo} className={styles.grantsLogo} alt={TEXTS.grants} />
        <Link to="/start" className={styles.speaker} title={TEXTS.startOfWar}>
            <img src={speaker} alt={TEXTS.startOfWar} />
        </Link>
    </footer>
)
