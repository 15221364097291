import React from 'react'
import { ControlElement } from './ControlElement'

import styles from './FromAuthors.module.css'

export const Controls = ({ current, count, onClick }) => {
    const children = []
    for (let i = 0; i < count; i++) {
        children.push(<ControlElement index={i} isActive={i === current} onClick={onClick} />)
    }
    return (
        <ul className={styles.controls}>
            {children}
        </ul>
    )
}
