import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import { Hero } from './Hero'
import styles from './Heroes.module.css'
import Img from 'gatsby-image'

import decalLeft from '../../images/background/decal-left.svg'
import decalRight from '../../images/background/decal-right.svg'
import { TEXTS } from '../../constants'

export const Heroes = () => {
    const { heroes, order, goldStar } = useStaticQuery(graphql`
    {
      heroes: allContentfulHero(sort: {fields: lastName}) {
        nodes {
          firstName
          lastName
          middleName
          birth(locale: "RU-ru")
          death(locale: "RU-ru")
          birthPlace {
            index
            title
          }
          photo {
            localFile {
              childImageSharp {
                fluid(cropFocus: CENTER, fit: COVER, grayscale: true, maxHeight: 290, maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          rank {
            rank
          }
          specialization {
            name
          }
          bio {
            json
          }
        }
      },
      order: file(relativePath: { eq: "order/order-of-lenin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      goldStar: file(relativePath: { eq: "order/gold-star-medal.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
    `)
    return (
        <div className={styles.heroes}>
            {heroes.nodes.map(({ id, ...props }) => <Hero {...props} key={id} />)}
            <div className={`${styles.decalContainer} ${styles.left}`}>
                <img src={decalLeft} className={styles.decal} alt={TEXTS.heroesLeft} />
            </div>
            <div className={`${styles.decalContainer} ${styles.right}`}>
                <img src={decalRight} className={styles.decal} alt={TEXTS.heroesRight} />
            </div>
            <Link to="order" title={TEXTS.highestAward} className={styles.order}>
                <Img fluid={goldStar.childImageSharp.fluid} alt={TEXTS.goldStar} />
                <Img fluid={order.childImageSharp.fluid} alt={TEXTS.order} />
            </Link>
        </div>
    )
}
