import React, { PureComponent } from 'react'

import styles from './Player.module.css'

export class Progress extends PureComponent {

    constructor (props) {
        super(props)
        this.progress = React.createRef()
    }

    componentDidMount () {
        this.progress.current.addEventListener('mousedown', this.onDown)
        document.addEventListener('mouseup', this.onUp)
    }

    onDown = (event) => {
        document.addEventListener('mousemove', this.onMove)
        this.onMove(event)
    }

    onMove = (event) => {
        const { onChange } = this.props
        const newRawValue = (event.clientX - this.progress.current.offsetLeft) / this.progress.current.offsetWidth
        const newValue = Math.max(0, Math.min(1, newRawValue))
        onChange(newValue)
    }

    onUp = () => {
        document.removeEventListener('mousemove', this.onMove)
    }

    render () {
        const { value, theme } = this.props

        return (
            <progress className={`${styles.progress} ${theme}`} ref={this.progress} value={value} />
        )
    }
}
