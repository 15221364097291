import React from 'react'
import { Link } from 'gatsby'

import { TEXTS } from '../../constants'
import victoryLogo from '../../images/victory-logo.svg'
import batyushkovLogo from '../../images/batyuchkov-logo.svg'
import headerText from '../../images/background/header.svg'
import quoteText from '../../images/background/quote.svg'

import styles from './layout.module.css'

export const Header = () => (
    <header className={styles.header}>
        <Link to="/75" className={styles.victoryLogo}>
            <img src={victoryLogo} alt={TEXTS.logo} />
        </Link>
        <a href="http://knbsociety.ru/ru/" className={styles.batyushkovLogo} title={TEXTS.batyushkov} target="_blank">
            <img src={batyushkovLogo} alt={TEXTS.batyushkov} />
        </a>
        <Link to="/" className={styles.headerText} title={TEXTS.siteTitle}>
            <img src={headerText} alt={TEXTS.siteTitle} />
        </Link>
        <img src={quoteText} className={styles.quoteText} alt={TEXTS.quote} />
    </header>
)
