import React from 'react'

import styles from './LightBox.module.css'

export const LightBox = ({ image, onClose }) => {
    if (image) {
        return (
            <div className={styles.container} onClick={onClose}>
                <img className={styles.image} src={image} />
            </div>
        )
    }
    return null
}
