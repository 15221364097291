import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import styles from './Video.module.css'

const SingleVideo = ({ title, fileMp4, fileWebm, fileImage }) => (
   <video className={styles.video} poster={fileImage.localFile.publicURL} autoPlay loop muted>
        <source src={fileMp4.localFile.publicURL} type="video/mp4" />
        <source src={fileWebm.localFile.publicURL} type="video/webm" />
        <img src={fileImage.localFile.publicURL} alt={title} />
    </video>
)

export const Video = () => {
    const { video } = useStaticQuery(graphql`
    {
      video: allContentfulMiniVideo(sort: {fields: index}) {
        nodes {
          id
          title
          fileMp4 {
            localFile {
              publicURL
            }
          }
          fileWebm {
            localFile {
              publicURL
            }
          }
          fileImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }`)
    return (
        <div className={styles.container}>
            {video.nodes.map(({ id, ...props}) => <SingleVideo key={id} {...props} />)}
        </div>
    )
}
