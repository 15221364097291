import React, { Fragment } from 'react'
import Img from 'gatsby-image'

import styles from './Gallery.module.css'

export const Gallery = ({ title, images, onImageClick }) => (
    <Fragment>
        <h2 className={styles.header}>{title}</h2>
        <div className={styles.gallery}>
            {images.map(({ id, localFile }) => (
                <a className={styles.image} key={id} href={localFile.publicURL} onClick={onImageClick}>
                    <Img fluid={localFile.childImageSharp.fluid} />
                </a>
            ))}
        </div>
    </Fragment>
)
