import React from 'react'

import { Player } from '..'
import { TEXTS } from '../../constants'

import styles from './songs.module.css'

const formatTitle = (number, title) => {
    if (number) {
        return `${number}. «${title}»`
    }
    return `«${title}»`
}

const getSingersTitle = (isShowSingersLabel, singers) => {
    if (isShowSingersLabel) {
        return singers.length > 1 ? TEXTS.singers : TEXTS.singer
    }
    return ''
}

export const Song = ({ title, year, singers, musicAuthor, lyricsAuthor, fileOgg, fileMp3, number, order, single, isLyricsAndMusicMerged, isShowSingersLabel }) => (
    <div className={`${styles.song} ${single && styles.single}`} style={{ order }}>
        <h2 className={styles.title}>{formatTitle(number, title)}</h2>
        <div className={styles.info}>
            <p>
                {musicAuthor && !isLyricsAndMusicMerged && <span>{`${TEXTS.music} ${musicAuthor}. `}</span>}
                {isLyricsAndMusicMerged && <span>{`${TEXTS.musicAndLyrics} ${musicAuthor}`}</span>}
                {lyricsAuthor && !isLyricsAndMusicMerged && <span>{`${TEXTS.lyrics} ${lyricsAuthor}`}</span>}
                {year && <span>{` (${year})`}</span>}
            </p>
            {singers && <p>{`${getSingersTitle(isShowSingersLabel, singers)} ${singers.join(' и ')}`}</p>}
        </div>
        <Player ogg={fileOgg.localFile.publicURL} mp3={fileMp3.localFile.publicURL}/>
    </div>
)
