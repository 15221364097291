import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Img from 'gatsby-image'

import styles from './text.module.css'
import { graphql, useStaticQuery } from 'gatsby'

const EmbeddedImage = ({ id }) => {
    const { assets } = useStaticQuery(graphql`
      {
        assets: allContentfulAsset {
          nodes {
            contentful_id
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `)
    const image = assets.nodes.find((node) => node.contentful_id === id)
    return <Img className={styles.image} fluid={image.localFile.childImageSharp.fluid} />
}

const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            if (node.data.target) {
                const { contentful_id } = node.data.target.sys
                return <EmbeddedImage id={contentful_id} />
            }
        },
    },
}

export const Text = ({ content }) => (
    <article className={styles.text}>
        {documentToReactComponents(content, options)}
    </article>
)
