let TEXTS = {
    siteTitle: 'Герои земли Череповецкой',
    gallery: 'Фотогалерея Победы',
    details: 'Подробнее',
    cherepovetsInfo: 'Череповец ради великой победы',
    cherepovetsMap: 'Карта размещения объектов военного времени в Череповце 1941-1945 гг',
    songs: 'Песни военных лет',
    important: 'Это важно знать!',
    goldStar: 'Медаль «Золотая Звезда»',
    order: 'Орден Ленина',
    decree: 'Постановление ЦИК',
    grants: 'Фонд президентских грантов',
    logo: '75 лет Победы',
    batyushkov: 'Батюшковское общество',
    quote: 'Героями не рождаются — героями становятся. А. В. Суворов',
    cherepovets: 'Череповец прифронтовой',
    heroesLeft: 'За нашу Советскую Родину!',
    heroesRight: 'Наше дело правое — мы победили!',
    prev: 'Предыдущее',
    next: 'Следующее',
    highestAward: 'Высшая награда Родины',
    music: 'Музыка',
    musicAndLyrics: 'Музыка и слова',
    lyrics: 'Слова',
    singer: 'Исполняет',
    singers: 'Исполняют',
    orderSubheader: 'Звание Героя Советского Союза было учреждено 16 апреля 1934 года постановлением ЦИК СССР, дополнительный знак отличия для Героя Советского Союза — медаль «Золотая Звезда» — учреждена Указом Президиума Верховного Совета СССР от 1 августа 1939 года.',
    orderCapture: 'Одни из первых Героев Советского Союза, участники экспедиции «Северный полюс-1» (слева направо): И. Т. Спирин, М. И. Шевелёв, М. С. Бабушкин, О. Ю. Шмидт, М. В. Водопьянов, А. Д. Алексеев, В. С. Молоков.',
    orderImportantHeroes: 'Герои Победы',
    orderImportantPioneers: 'Пионеры герои',
    mother: 'Родина-Мать зовёт',
    muscovites: 'Москвичи',
    sacredWar: 'Вставай, страна огромная!',
    appealVideo: 'Смотреть видео',
    play: 'Запустить',
    pause: 'Остановить',
    startOfWar: 'Начало Войны',
    levitan: 'Юрий Борисович Левитан',
    stalin: 'Сталин Иосиф Виссарионович',
    firework: 'Праздничный салют',
    europe: 'От Австралии до Японии: все участники Второй мировой'
}

const initTexts = (newTexts) => {
    TEXTS = Object.assign(TEXTS, newTexts)
}

export {
    TEXTS,
    initTexts
}