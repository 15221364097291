import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { TEXTS } from '../../constants'

import styles from './Cherepovets.module.css'

export const Cherepovets = () => {
    const { cherepovetsPhoto } = useStaticQuery(graphql`
    {
      cherepovetsPhoto: contentfulAsset(title: {eq: "Череповец аэрофотосъёмка"}) {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      },
    }
    `)
    return (
        <Link to="cherepovets" className={styles.link} title={TEXTS.cherepovets}>
            <Img fluid={cherepovetsPhoto.localFile.childImageSharp.fluid} alt={TEXTS.cherepovets} />
            <div className={styles.title}>{TEXTS.cherepovets}</div>
        </Link>
    )
}