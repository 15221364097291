import React from 'react'

import styles from './Player.module.css'

export const TimeStamp = ({ time }) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return (
        <span className={styles.timestamp}>{`${minutes}:${seconds.toString().padStart(2, '0')}`}</span>
    )
}
