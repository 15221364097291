import React from 'react'
import Img from 'gatsby-image'

import { Text } from '..'

import styles from './FromAuthors.module.css'

export const FromAuthor = ({ fio, position, photo, text }) => (
    <div className={styles.fromAuthor}>
        <div className={styles.photoRow}>
            <div className={styles.photoContainer}>
                <Img className={styles.photo} fluid={photo.localFile.childImageSharp.fluid} alt={fio} />
            </div>
        </div>
        <div className={styles.infoRow}>
            <div className={styles.fio}>{fio}</div>
            <div className={styles.position}>{position}</div>
            <Text content={text.json} />
        </div>
    </div>
)
