import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import cyrillicToTranslit from 'cyrillic-to-translit-js'

import { MAP_EVENTS } from '../../constants'
import { formatDates, getFio, getShortFio } from '../../utils'

import styles from './Heroes.module.css'

const NO_BREAK_SPACE = ' '

export class Hero extends PureComponent {

    constructor (props) {
        super(props)
        this.link = React.createRef()
    }

    componentDidMount () {
        this.link.current.addEventListener('mouseenter', this.onEnter)
        this.link.current.addEventListener('mouseleave', this.onLeave)
        document.addEventListener(MAP_EVENTS.POINT_ENTER, this.onEvent(this.addClass).bind(this))
        document.addEventListener(MAP_EVENTS.POINT_LEAVE, this.onEvent(this.removeClass).bind(this))
        document.addEventListener(MAP_EVENTS.POINT_CLICK, this.onEvent(this.click).bind(this))
    }

    onEnter = () => {
        this.addClass()
        this.dispatch(MAP_EVENTS.HERO_ENTER)
    }

    onLeave = () => {
        this.removeClass()
        this.dispatch(MAP_EVENTS.HERO_LEAVE)
    }

    onEvent = (callback) => ({ detail }) => {
        if (detail === this.props.birthPlace.index && this.link.current) {
            callback()
        }
    }

    addClass = () => this.link.current.classList.add(styles.active)
    removeClass = () => this.link.current.classList.remove(styles.active)
    click = () => this.link.current.click()
    dispatch = (eventType) => document.dispatchEvent(new CustomEvent(eventType, { detail: this.props.birthPlace.index }))

    render() {
        const { photo, firstName, lastName, middleName, birth, death, birthPlace } = this.props
        const { title: birthPlaceTitle } = birthPlace
        const fio = getFio(lastName, firstName, middleName)
        const shortFio = getShortFio(lastName, firstName, middleName)
        const url = cyrillicToTranslit().transform(lastName).toLowerCase()
        return (
            <Link to={`/${url}`} className={styles.hero} ref={this.link}>
                <Img fluid={photo.localFile.childImageSharp.fluid} alt={fio} />
                <div className={styles.info}>
                    <div className={styles.fio}>{shortFio}</div>
                    <div>{`(${formatDates(birth, death)})`}</div>
                    <div>{birthPlaceTitle}</div>
                </div>
            </Link>
        )
    }
}
