import React, { Fragment } from 'react'

import { Header } from './Header'
import { Footer } from './Footer'
import { Helmet } from 'react-helmet'

import styles from './layout.module.css'

export const Layout = ({ header, pageTitle, children }) => (
    <Fragment>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{pageTitle}</title>
        </Helmet>
        <Header />
        {header && <h1>{header}</h1>}
        <main className={styles.content}>
            {children}
        </main>
        <Footer />
    </Fragment>
)
