import React, { PureComponent } from 'react'

import { MAP_EVENTS } from '../../constants'
import MapImage from '../../images/map.inline.svg'

import styles from './map.module.css'

export class Map extends PureComponent {

    constructor (props) {
        super(props)
        this.image = React.createRef()
    }

    componentDidMount () {
        this.image.current.querySelectorAll('.place').forEach((place) => {
            place.addEventListener('mouseenter', this.onEnter)
            place.addEventListener('mouseleave', this.onLeave)
            place.addEventListener('click', this.onClick)
        })
        document.addEventListener(MAP_EVENTS.HERO_ENTER, this.onEvent(this.addClass).bind(this))
        document.addEventListener(MAP_EVENTS.HERO_LEAVE, this.onEvent(this.removeClass).bind(this))
    }

    onEnter = (event) => {
        this.addClass(event.target)
        this.dispatch(MAP_EVENTS.POINT_ENTER, event.target.id)
    }

    onLeave = (event) => {
        this.removeClass(event.target)
        this.dispatch(MAP_EVENTS.POINT_LEAVE, event.target.id)
    }

    onEvent = (callback) => ({ detail }) => {
        if (this.image.current) {
            const place = this.image.current.querySelector(`#place-${detail}`)
            callback(place)
        }
    }

    onClick = (event) => this.dispatch(MAP_EVENTS.POINT_CLICK, event.target.id)
    addClass = (node) => node.classList.add(styles.active)
    removeClass = (node) => node.classList.remove(styles.active)

    dispatch = (eventType, pointId) => {
        const id = parseInt(pointId.split('-')[1], 10)
        const event = new CustomEvent(eventType, { detail: id })
        document.dispatchEvent(event)
    }

    render () {
        return (
            <div ref={this.image}>
                <MapImage className={styles.map} />
            </div>
        )
    }
}
