import React from 'react'
import { Link } from 'gatsby'

import styles from './Button.module.css'

export const Button = ({ to, text, icon, mode, external }) => {
    const theme = `${styles.button} ${styles[mode]}`
    if (external) {
        return <a href={to} className={theme} title={text} target="_blank">
            {text}
        </a>
    }
    const style = icon ? { backgroundImage: `url(${icon})` } : void 0
    return (
        <Link to={to} style={style} className={theme} title={text}>
            {text}
        </Link>
    )
}
